<template>
  <vxe-modal
    v-model="open"
    width="50%"
    height="100%"
    :title="formTitle"
    :show-zoom="true"
    resize
    :lock-view="false"
    :mask="true"
    @close="onClose"
    :showHeader="source !== 'menu'"
    :className="source === 'menu' ? 'menu-expense-wrap' : ''"
  >
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="申请人" prop="applicantId">
        {{ userInfo.userName }}
      </a-form-model-item>
      <a-form-model-item label="是否可摊销" prop="isAmortize">
        <a-radio-group v-model="form.isAmortize" button-style="solid" @change="onIsAmortizeChange">
          <a-radio-button v-for="(d, index) in isAmortizeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="摊销年数" prop="amortizeYears" v-if="form.isAmortize == 'Y'">
        <a-input v-model="form.amortizeYears" placeholder="请输入摊销年数" type="Number" />
      </a-form-model-item>
      <a-form-model-item label="费用类型" prop="expenseType">
        <a-select placeholder="请选择费用类型" v-model="form.expenseType" @change="onExpenseTypeChange">
          <a-select-option v-for="(d, index) in expenseTypeOptions2" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="申请时间" prop="applyTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.applyTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          disabled
        />
      </a-form-model-item>
      <a-form-model-item label="月费用加总" prop="expenseTotal">
        <a-input v-model="form.expenseTotal" placeholder="" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="所属项目" prop="deptId">
        <a-select placeholder="请选择所属项目" v-model="form.deptId">
          <a-select-option v-for="(d, index) in projectOptions" :key="index" :value="d.deptId">{{
            d.deptName
          }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="采购明细" class="expense-list">
        <expense-list ref="listRef" :cform="form" :crules="rules" :unitOptions="unitOptions" @setAmount="setAmount">
        </expense-list>
      </a-form-model-item>
      <a-form-model-item label="申请金额" prop="applyAmount">
        <a-input v-model="form.applyAmount" placeholder="请输入申请金额" />
      </a-form-model-item>
      <a-form-model-item label="上传单据" prop="photo">
        <!-- <file-upload v-model="form.billImage" folder="expenses" type="image"></file-upload> -->
        <many-file-upload
          :billImageUrl="form.billImageUrl"
          v-model="form.billImage"
          folder="expenses"
          type="image"
          :maxFileCount="15"
        ></many-file-upload>
      </a-form-model-item>
      <a-form-model-item label="是否有专票" prop="isSpecialTicket">
        <a-radio-group v-model="form.isSpecialTicket" button-style="solid">
          <a-radio-button v-for="(d, index) in isAmortizeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="交易对手方" prop="counterparty">
        <a-input v-model="form.counterparty" placeholder="请输入交易对手方" />
      </a-form-model-item>
      <a-form-model-item label="付款方式" prop="paymentType">
        <a-radio-group v-model="form.paymentType" button-style="solid">
          <a-radio-button v-for="(d, index) in paymentTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="实际付款人" prop="actualPayer" v-if="form.paymentType == 2 || form.paymentType == 3">
        <a-select
          show-search
          placeholder="请输入实际付款人姓名查找"
          v-model="form.actualPayer"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          @search="handleEmployeeSearch"
          @change="handleEmployeeChange"
          :loading="loading"
        >
          <a-select-option v-for="d in employeeArray" :key="d.id" :value="d.name">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input type="textarea" v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </vxe-modal>
</template>

<script>
import debounce from 'lodash/debounce'
import { getExpenses, addExpenses, updateExpenses, countExpenses, getProjects } from '@/api/iot/expenses'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import ExpenseList from './ExpenseList'
import { mapState } from 'vuex'
import { parseDateTime } from '@/utils/ruoyi'
import ManyFileUpload from '@/components/ManyFileUpload'

export default {
  name: 'CreateForm',
  props: {
    isAmortizeOptions: {
      type: Array,
      default: () => []
    },
    expenseTypeOptions: {
      type: Array,
      default: () => []
    },
    // 付款方式
    paymentTypeOptions: {
      type: Array,
      default: () => []
    },
    projectOptions: {
      type: Array,
      default: () => []
    },
    unitOptions: {
      type: Array,
      default: () => []
    },
    source: {
      type: String,
      default: ''
    }
  },
  components: {
    ExpenseList,
    ManyFileUpload
  },
  data() {
    this.handleEmployeeSearch = debounce(this.handleEmployeeSearch, 500)
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        applicantId: null,
        expenseType: null,
        applyTime: null,
        applyAmount: null,
        expenseTotal: null,
        isAmortize: '0',
        amortizeYears: null,
        approvalStatus: '0',
        createTime: null,
        remark: null,
        purchaseList: [
          {
            content: '',
            quantity: '',
            unit: '',
            price: '',
            amount: ''
          }
        ]
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        expenseType: [{ required: true, message: '费用类型不能为空', trigger: 'change' }],
        applyTime: [{ required: true, message: '申请时间不能为空', trigger: 'change' }],
        applyAmount: [{ required: true, message: '申请金额不能为空', trigger: 'blur' }],
        isAmortize: [{ required: true, message: '是否可摊销不能为空', trigger: 'blur' }],
        approvalStatus: [{ required: true, message: '审批状态不能为空', trigger: 'blur' }],
        counterparty: [{ required: true, message: '交易对手方不能为空', trigger: 'blur' }],
        isSpecialTicket: [{ required: true, message: '是否有专票不能为空', trigger: 'blur' }],
        paymentType: [{ required: true, message: '付款方式不能为空', trigger: 'blur' }],
        actualPayer: [{ required: true, message: '实际付款人不能为空', trigger: 'blur' }],
        deptId: [{ required: true, message: '所属项目不能为空', trigger: 'blur' }]
      },
      expenseTypeOptions2: [],
      billImageArray: []
    }
  },
  filters: {},
  created() {},
  computed: {
    ...mapState({
      userInfo: state => state.user.info
    })
  },
  watch: {},
  mounted() {
    console.log('userInfo', this.userInfo)
    console.log('this.isAmortizeOptions', this.isAmortizeOptions)
    if (this.source === 'menu') {
      this.getDictData()
    }
  },
  methods: {
    async getDictData() {
      this.loading = true
      await this.getDicts('sys_yes_no').then(response => {
        this.isAmortizeOptions = response.data
      })
      await this.getDicts('iot_expense_type').then(response => {
        this.expenseTypeOptions = response.data
      })
      await this.getDicts('iot_approval_status_type').then(response => {
        this.approvalStatusTypeOptions = response.data
      })
      await getProjects({}).then(response => {
        this.projectOptions = response.data
      })
      await this.getDicts('iot_account_unit').then(response => {
        this.unitOptions = response.data.map(p => p.dictValue)
      })
      this.paymentTypeOptions = [
        {
          dictLabel: '财务直接对外付款',
          dictValue: '1'
        },
        {
          dictLabel: '员工自己付款后报销',
          dictValue: '2'
        },
        {
          dictLabel: '备用金冲抵',
          dictValue: '3'
        }
      ]
      this.handleAdd()
      this.loading = false
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        applicantId: null,
        expenseType: null,
        applyTime: null,
        applyAmount: null,
        expenseTotal: null,
        isAmortize: '0',
        amortizeYears: null,
        approvalStatus: '0',
        createTime: null,
        purchaseList: [
          {
            content: null,
            quantity: null,
            unit: null,
            price: null,
            amount: null
          }
        ],
        billImage: '',
        isSpecialTicket: null,
        counterparty: null,
        paymentType: null,
        actualPayer: null,
        img: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.isAmortize = 'N'
      this.form.expenseTotal = 0
      this.form.applyTime = parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss')
      console.log('form', this.form)
      this.setExpenseTypeOption2('N')
      console.log('projectOptions', this.projectOptions)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getExpenses(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.setExpenseTypeOption2(this.form.isAmortize)
        this.$forceUpdate()
      })
    },
    doBeforeSave() {
      // 设置默认的申请人
      this.form.applicantId = this.userInfo.userId
    },
    /** 提交按钮 */
    submitForm: function() {
      console.log('submitForm')
      this.$refs.listRef.$refs.form.validate(valid => {
        if (valid) {
          this.form.purchaseList = this.$refs.listRef.getPurchaseList()
          console.log('purchaseList', this.form.purchaseList)
          this.$refs.form.validate(valid => {
            if (valid) {
              this.doBeforeSave()
              if (this.form.id !== undefined && this.form.id !== null) {
                updateExpenses(this.form).then(response => {
                  this.$message.success('修改成功', 3)
                  this.open = false
                  this.$emit('ok')
                })
              } else {
                addExpenses(this.form).then(response => {
                  this.$message.success('新增成功', 3)
                  this.open = false
                  this.$emit('ok')
                })
              }
            } else {
              return false
            }
          })
        }
      })
    },
    handleEmployeeSearch(value) {
      console.log('handleEmployeeSearch', value)
      const queryParam = {
        name: value
      }
      this.loading = true
      listEmployeeAccount(queryParam).then(response => {
        this.employeeArray = response.rows
        this.loading = false
        this.$forceUpdate()
      })
    },

    handleEmployeeChange(value) {
      this.form.actualPayer = value
    },
    setAmount(amount) {
      console.log('amount', amount)
      this.form.applyAmount = amount
    },
    onIsAmortizeChange(e) {
      console.log('onIsAmortizeChange', e.target.value)
      const isAmortize = e.target.value
      this.form.expenseType = ''
      this.setExpenseTypeOption2(isAmortize)
    },
    setExpenseTypeOption2(isAmortize) {
      if (isAmortize === 'Y') {
        this.expenseTypeOptions2 = this.expenseTypeOptions.filter(p => p.dictValue >= 100)
      } else {
        this.expenseTypeOptions2 = this.expenseTypeOptions.filter(p => p.dictValue < 100)
      }
      this.$forceUpdate()
    },
    onExpenseTypeChange(e) {
      countExpenses(e).then(response => {
        this.form.expenseTotal = response.expenseTotal
      })
    }
  }
}
</script>

<style lang="less" scoped>
.expense-list {
  margin-bottom: 50px;
}
.menu-expense-wrap {
  position: initial !important;
  /deep/ .vxe-modal--box {
    box-shadow: none !important;
    border-color: transparent !important;
    position: inherit !important;
  }
}
</style>
