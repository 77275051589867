import request from '@/utils/request'

// 查询费用记录列表
export function listExpenses(query) {
  return request({
    url: '/iot/expenses/list',
    method: 'get',
    params: query
  })
}

// 查询费用记录详细
export function getExpenses(id) {
  return request({
    url: '/iot/expenses/' + id,
    method: 'get'
  })
}

// 新增费用记录
export function addExpenses(data) {
  return request({
    url: '/iot/expenses',
    method: 'post',
    data: data
  })
}

// 修改费用记录
export function updateExpenses(data) {
  return request({
    url: '/iot/expenses',
    method: 'put',
    data: data
  })
}

// 删除费用记录
export function delExpenses(id) {
  return request({
    url: '/iot/expenses/' + id,
    method: 'delete'
  })
}

// 导出费用记录
export function exportExpenses(query) {
  return request({
    url: '/iot/expenses/export',
    method: 'get',
    params: query
  })
}
// 计算费用类型当月加总
export function countExpenses(id) {
  return request({
    url: '/iot/expenses/countExpenses/' + id,
    method: 'get'
  })
}
// 获取审批详情
export function getApprovalDetail(id) {
  return request({
    url: '/iot/expenses/getApprovalDetail/' + id,
    method: 'get'
  })
}
// 审批处理
export function completeApproval(data) {
  return request({
    url: '/iot/expenses/completeApproval',
    method: 'post',
    data: data
  })
}
// 费用记录查看审批记录
export function getApprovalProcess(id) {
  return request({
    url: '/iot/expenses/getApprovalProcess/' + id,
    method: 'get'
  })
}

// 查询我的费用申请列表
export function myExpenses(query) {
  return request({
    url: '/iot/expenses/myExpenses',
    method: 'get',
    params: query
  })
}

// 获取当前用户的项目列表
export function getProjects(query) {
  return request({
    url: '/system/dept/getProjects',
    method: 'get',
    params: query
  })
}

// 获取我的待办列表
export function myPendingExpenses(query) {
  return request({
    url: '/iot/expenses/myPendingExpenses',
    method: 'get',
    params: query
  })
}
