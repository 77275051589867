var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "50%",
        height: "100%",
        title: _vm.formTitle,
        "show-zoom": true,
        resize: "",
        "lock-view": false,
        mask: true,
        showHeader: _vm.source !== "menu",
        className: _vm.source === "menu" ? "menu-expense-wrap" : "",
      },
      on: { close: _vm.onClose },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "申请人", prop: "applicantId" } },
            [_vm._v(" " + _vm._s(_vm.userInfo.userName) + " ")]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否可摊销", prop: "isAmortize" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  on: { change: _vm.onIsAmortizeChange },
                  model: {
                    value: _vm.form.isAmortize,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isAmortize", $$v)
                    },
                    expression: "form.isAmortize",
                  },
                },
                _vm._l(_vm.isAmortizeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.isAmortize == "Y"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "摊销年数", prop: "amortizeYears" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入摊销年数", type: "Number" },
                    model: {
                      value: _vm.form.amortizeYears,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "amortizeYears", $$v)
                      },
                      expression: "form.amortizeYears",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "费用类型", prop: "expenseType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择费用类型" },
                  on: { change: _vm.onExpenseTypeChange },
                  model: {
                    value: _vm.form.expenseType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "expenseType", $$v)
                    },
                    expression: "form.expenseType",
                  },
                },
                _vm._l(_vm.expenseTypeOptions2, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "申请时间", prop: "applyTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  disabled: "",
                },
                model: {
                  value: _vm.form.applyTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "applyTime", $$v)
                  },
                  expression: "form.applyTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "月费用加总", prop: "expenseTotal" } },
            [
              _c("a-input", {
                attrs: { placeholder: "", disabled: true },
                model: {
                  value: _vm.form.expenseTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "expenseTotal", $$v)
                  },
                  expression: "form.expenseTotal",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属项目", prop: "deptId" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择所属项目" },
                  model: {
                    value: _vm.form.deptId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deptId", $$v)
                    },
                    expression: "form.deptId",
                  },
                },
                _vm._l(_vm.projectOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.deptId } },
                    [_vm._v(_vm._s(d.deptName))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { staticClass: "expense-list", attrs: { label: "采购明细" } },
            [
              _c("expense-list", {
                ref: "listRef",
                attrs: {
                  cform: _vm.form,
                  crules: _vm.rules,
                  unitOptions: _vm.unitOptions,
                },
                on: { setAmount: _vm.setAmount },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "申请金额", prop: "applyAmount" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入申请金额" },
                model: {
                  value: _vm.form.applyAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "applyAmount", $$v)
                  },
                  expression: "form.applyAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "上传单据", prop: "photo" } },
            [
              _c("many-file-upload", {
                attrs: {
                  billImageUrl: _vm.form.billImageUrl,
                  folder: "expenses",
                  type: "image",
                  maxFileCount: 15,
                },
                model: {
                  value: _vm.form.billImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "billImage", $$v)
                  },
                  expression: "form.billImage",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否有专票", prop: "isSpecialTicket" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.isSpecialTicket,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isSpecialTicket", $$v)
                    },
                    expression: "form.isSpecialTicket",
                  },
                },
                _vm._l(_vm.isAmortizeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "交易对手方", prop: "counterparty" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入交易对手方" },
                model: {
                  value: _vm.form.counterparty,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "counterparty", $$v)
                  },
                  expression: "form.counterparty",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "付款方式", prop: "paymentType" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.paymentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "paymentType", $$v)
                    },
                    expression: "form.paymentType",
                  },
                },
                _vm._l(_vm.paymentTypeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.paymentType == 2 || _vm.form.paymentType == 3
            ? _c(
                "a-form-model-item",
                { attrs: { label: "实际付款人", prop: "actualPayer" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        placeholder: "请输入实际付款人姓名查找",
                        "default-active-first-option": false,
                        "show-arrow": false,
                        "filter-option": false,
                        loading: _vm.loading,
                      },
                      on: {
                        search: _vm.handleEmployeeSearch,
                        change: _vm.handleEmployeeChange,
                      },
                      model: {
                        value: _vm.form.actualPayer,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "actualPayer", $$v)
                        },
                        expression: "form.actualPayer",
                      },
                    },
                    _vm._l(_vm.employeeArray, function (d) {
                      return _c(
                        "a-select-option",
                        { key: d.id, attrs: { value: d.name } },
                        [_vm._v(" " + _vm._s(d.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: { type: "textarea", placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }